<template>
    <div class="content-section">
            <!-- signup page -->
            <div class="review-flight py-5">
                <div class="container">
                    <div class="row registerCard">
                        <div class="col-12 col-md-6 offset-md-3">
                            <div class="theme-box-shadow theme-border-radius theme-bg-white p-3">
                                <div class="d-flex align-items-center mb-3">
                                    <div class="flex-shrink-0">
                                        <div class="theme-bg-primary p-3 rounded-circle"><i
                                                class="bi bi-person-plus lh-1 fs-4" style="color:white;"></i></div>

                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <span class="fs-4 fw-bold">Create {{ siteTitle }} Account</span>
                                        <p class="font-medium mb-0 theme-text-accent-one">For security, please sign in
                                            to access your information
                                        </p>
                                    </div>
                                </div>
                                <div class="col-12">
                                <v-form ref="form" v-model="valid" @submit.prevent="registerSubmitForm" style="width: 100%;">
                                    <!-- <form class="needs-validation" novalidate> -->
                                        <div class="form-floating mb-3">
                                          <v-text-field v-model="personal.username" :rules="userName" label="Name"
                                        variant="outlined" placeholder="Name"
                                        style="height: 60px;"></v-text-field>
                                        </div>
                                        <div class="form-floating mb-3">
                                          <v-text-field v-model="personal.email" :rules="eMail" label="Email ID"
                                        placeholder="Email ID" type="email" variant="outlined"
                                        style="height: 60px;"></v-text-field>
                                        </div>
                                        <!-- <div class="form-floating mb-3">
                                            <input type="number" class="form-control" id="floatingInput3"
                                                placeholder="Number">
                                            <label for="floatingInput3">Number</label>
                                        </div>
                                        <div class="form-floating mb-3">
                                            <input type="number" class="form-control" id="floatingInput4"
                                                placeholder="OTP">
                                            <label for="floatingInput4">OTP</label>
                                        </div> -->
                                        <div class="form-floating mb-3">
                                          <v-text-field v-model="personal.password" :rules="passWord" label="Password"
                                        placeholder="Password" variant="outlined" required
                                        :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                                        :type="visible ? 'text' : 'password'" @click:append-inner="visible = !visible"
                                        style="height: 60px;"></v-text-field>
                                        </div>
                                        <div class="form-floating mb-3">
                                          <v-text-field v-model="personal.rePassword" :rules="repassWord" label="Confirm Password"
                                        placeholder="Confirm Password" variant="outlined" required
                                        :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                                        :type="visible ? 'text' : 'password'" @click:append-inner="visible = !visible"
                                        style="height: 60px;"></v-text-field>
                                        </div>
                                        <div class="mb-3 form-check">
                                            <input type="checkbox" class="form-check-input" id="exampleCheck2">
                                            <label class="form-check-label font-small" for="exampleCheck2">By clicking
                                                on register, I Understand & agree to world travel <a href="#">Terms and
                                                    condition</a>
                                                and <a href="#">Privacy Policy</a></label>
                                        </div>
                                        <div class="mb-3 d-flex flex-md-row flex-column align-center">
                                            <button type="submit" class="btn btn-effect btn-book px-5 min-h40">
                                              <v-progress-circular v-if="loadRegister" :width="5" color="white" indeterminate></v-progress-circular>
                                            {{ !loadRegister ? 'Register' : '' }}</button>
                                            <span class="font-medium">
                                                <span class="ms-3 me-2">You have already an account?</span>
                                                <a href="/login">Login</a>
                                            </span>
                                        </div>
                                    <!-- </form> -->
                                  </v-form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>

    <div class="d-none toast" :class="isActive ? 'active' : ''">
        <div class="toast-content">
          <i class="bi bi-check toast-check"></i>
          <div class="message">
            <span class="message-text text-1">Success</span>
            <span class="message-text text-2">Your {{ Successmsg }}</span>
          </div>
        </div>

        <i class="bi bi-x-lg toast-close" @click="closeToast"></i>
        <div class="progress" :class="isActive ? 'active' : ''" :style="{ '--progress-color': progressColor }"></div>
    </div>
    <div class="d-none toast" :class="isNotActive ? 'active border-danger' : ''">
        <div class="toast-content">
          <i class="bi bi-x-lg toast-check" :class="isNotActive ? 'bg-danger' : 'bg-danger'"></i>
          <div class="message">
            <span class="message-text text-1">{{ ErrorMessage }}</span>
            <span class="message-text text-2">Please Enter Correct Details</span>
          </div>
        </div>

        <i class="bi bi-x-lg toast-close" @click="closeToast"></i>
        <div class="progress" :class="isNotActive ? 'active' : ''" :style="{ '--progress-color': progressColor }"></div>
    </div>


  <div v-if="loginSection">
    <loginCom></loginCom>
  </div>
</template>

<script>
import loginCom from '@/components/loginCom.vue';
import axios from 'axios';

import { getConfigDataFromLocalStorage } from '@/localStorageData/localStorageUtil.js';

export default {
  components: {
    loginCom
  },
  data() {
    return {

      valid: false,

      registerbox: true,
      loginSection: false,
      terms: false,
      visible: false,

      regForm: true,
      regMsg: false,
      registerMsg: "",
      loadRegister: false,
      btnDisabled: false,

      personal: {
        username: "",
        email: "",
        password: "",
        rePassword: ""
      },
      data1: {
        "request_type": "user_register",
        "user_name": "admin",
        "email": "admin12553435@gmail.com",
        "password": "1234567890",
        "portal_id": "11111",
        "portal_group_id": "11111",
        "user_id": "0000",
      },


      userName: [
        v => !!v || 'Please enter your Username',
        v => (v.length >= 3) || 'Name must be greater than 3 ',
      ],

      eMail: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid',
      ],

      passWord: [
        v => !!v || 'Please enter your Password',
        // v => (v && v.length >= 8) || 'Password must be at least 8 characters',
        // v => (v && v.length <= 20) || 'Password must be less than 20 characters',
        // v => (v && /[a-z]/.test(v)) || 'Password must contain at least one lowercase letter',
        // v => (v && /[!@#$%^&*]/.test(v)) || 'Password must contain at least one special character'
      ],
      repassWord: [
        v => !!v || 'Please enter your Confirm Password',
        v => v === this.personal.password || 'Passwords do not match'
      ],

      // strResult: "0123456789",

      // user_reqId:"",


      registerReq: {
        "request_type": "user_register",
        "user_name": "",
        "email": "",
        "password": "",
        "portal_id": "11111",
        "portal_group_id": "11111",
        "user_id": "",
        "token": "Tc7RQaX79Hrx5CFK"
      },

      registerUrl:"",

      isActive:false,
      siteTitle:"",
      portalId:"",
      portalGroupId:"",
    };
  },
  methods: {
   showToast() {
      this.isActive = true;
      // setTimeout(() => {
      //   if(this.isActive){
      //       this.isActive = false;
      //   }
      // }, 5000);
    },
    closeToast() {
      this.isActive = false;
      this.isNotActive = false;
    },

    signInAgain(){
      this.loginSection = true 
      this.registerbox = false
      console.log(this.loginSection, this.registerbox, "Workinglogin....")
    },
    // closeRegBox() {
    //         this.loginbox = false;
    //         this.$emit('close'); // Emit the 'close' event to the parent component
    //         console.log(this.loginbox, "1111111")
    //     },
  onClickOutside() {
      this.registerbox = false
    },
   registerSubmitForm() {

      console.log(this.valid, 'oooooooooo')
      if (this.valid) {

        this.loadRegister = true;
        this.btnDisabled = true;

        // this.generateBookingRefId()

        console.log(this.personal, 'eeeee......')

        this.registerReq.user_name = this.personal.username
        this.registerReq.email = this.personal.email
        this.registerReq.password = this.personal.password
        this.registerReq.user_id = this.personal.email
        this.registerReq.portal_id = this.portalId
        this.registerReq.portal_group_id = this.portalGroupId


        console.log(this.registerReq, 'ffff.....')

        axios.post(this.registerUrl, this.registerReq, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then(response => {

            if (response.data) {
              this.regForm = false
              this.regMsg = true

              this.isActive =  true

              console.log('Response:', response.data);
              console.log('Response-Msg:', response.data.register.message);
              // this.registerMsg = response.data.register.message

              this.Successmsg = response.data.register.message


              setTimeout(()=>{
                            this.isActive =  false;
                            this.loadRegister = false;
                            this.$refs.form.reset()
                        },5000)

              // this.registerbox = false
            }
          })


          .catch(error => {
            console.log(error.response.data.message, 'wejjfuoajk')
            alert(`${error.response.data.message}`)
            this.loadRegister = false
            this.btnDisabled = false
            this.isNotActive = true;
            setTimeout(()=>{
                            this.isNotActive =  false;
                        },5000)
          });
      }
    },




    // getRandomChars(length) {
    //         let result = "";
    //         for (let i = 0; i < length; i++) {
    //             const randomIndex = Math.floor(Math.random() * this.strResult.length);
    //             result += this.strResult[randomIndex];
    //         }
    //         console.log(result, "result");
    //         return result;
    //     },
    //     getRandomInt(min, max) {
    //         return Math.floor(Math.random() * (max - min + 1)) + min;
    //     },

    //     generateBookingRefId() {
    //         const randomChars1 = this.getRandomChars(1);
    //         const randomNum = this.getRandomInt(10, 99);
    //         const randomChars2 = this.getRandomChars(1);

    //         this.registerReq.user_id = randomChars1 + randomNum + randomChars2;
    //         console.log(this.registerReq.user_id, 'this.booking_refId ')
    //     },




    closeRegisterBox() {
      this.registerbox = false;
      this.$emit('close'); // Emit the 'close' event to the parent component
      console.log(this.registerbox, '00000')
    },



    getConfig() {
      // let portalLocal = localStorage.getItem("portalData")
      // let getConfigData1 = JSON.parse(portalLocal)
      const getConfigData1 = getConfigDataFromLocalStorage();
      if (getConfigData1) {
        this.registerUrl = getConfigData1.payload.portal_configuration.API_endpoints.b2cregister
        this.siteTitle = getConfigData1.payload.portal_configuration.site_title

        this.portalId = getConfigData1.payload.portal_configuration.portal_id
        this.portalGroupId = getConfigData1.payload.portal_configuration.portal_group_id
        console.log(this.registerUrl, 'head...reg..1..')
      }
    },
  },

  computed: {
        progressColor() {
         return this.isActive ? '#40f467' : '#ff0000';
        },
    },

  created() {  
    this.getConfig();
  }
}
</script>



<style scoped>

@import "@/assets/main.css";



.main-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* .form-btn {
  padding-left: 40px;
  color: white;
} */

.btn-box {
  background-color: transparent;
  margin: 5px;
  padding: 2px;
  box-shadow: none;
}

.btn-active {
  background-color: blueviolet;
  color: white;
}

>>>.v-input__details {
  display: block !important;
  z-index: 99;
}


/* .v-checkbox .v-input__details {
  display: none;
} */




>>>.registerCard .v-input__control {
  height: 45px;
}

>>>.registerCard .v-field__field {
  height: 45px;
}

>>>.v-field--focused .v-label.v-field-label{
  background-color: #ffffff !important;
  padding: 0 2px;
}

>>>.registerCard .v-field--focused {
  color: #002d5b;
  background-color: #ffffff;
  border-color: #ffc104;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 4, 0.5);
  border: none !important;
}
>>>.registerCard .v-field--variant-outlined.v-field--focused .v-field__outline {
  --v-field-border-width: 0px !important;
}

>>>.registerCard .v-text-field .v-field--no-label input,
.v-text-field .v-field--active input {
  opacity: 1;
  font-size: 15px;
  font-weight: 400;
  color: black;
  padding-top: 8px;
}


>>>.registerCard .v-field__input input::placeholder {
    position: relative;
    top: -10px;
}

>>>.registerCard .v-autocomplete .v-field__input input::placeholder {
    position: relative;
    top: 0px;
    
}

/* Login Page End */





/* PopMessage Section */

body{
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  overflow: hidden;
}

.toast{
  position: absolute;
  top: 80px;
  right: 10px;
  border-radius: 6px;
  background: #fff;
  padding: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  border-left: 8px solid #40f467;
  overflow: hidden;
  transform: translateX(calc(100% + 30px));
  transition: all 0.5s cubic-bezier(0.68, -0.55, 0.25, 1.35);
}
.toast.active{
  transform: translateX(0);
}
.toast-content{
  display: flex;
  justify-content: center;
  align-items: center;
}
.toast-check{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 70px;
  background-color: #40f467;
  border-radius: 50%;
  color: #fff;
  font-size: 20px;
}
.message{
  display: flex;
  flex-direction: column;
  margin: 0 20px;
}
.message-text{
  font-size: 20px;
  font-weight: 600;
}
.text-1{
  color: #333;
}
.text-2{
  color: #666;
  font-weight: 400;
  font-size: 13px;
}
.toast-close{
  position: absolute;
  top: 10px;
  right: 15px;
  padding: 5px;
  cursor: pointer;
  opacity: 0.7;
}
.toast-close:hover{
  opacity: 1;
}
.progress{
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  width: 100%;
  background: #ddd;
}

.progress::before{
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: var(--progress-color, #40f467);
  background-color: var(--progress-color, #ff0000);
}
.progress.active::before{
  animation: progress 5s linear forwards;
}

.toast:not(.show) {
    display: block;
}


@keyframes progress {
  100%{
    right: 100%;
  }
}
.toast-btn{
  padding: 10px 40px;
  font-size: 20px;
  outline: none;
  border: none;
  background-color: #40f467;
  color: #fff;
  border-radius: 50px;
  cursor: pointer;
  transition: 0.3s;
}
.toast-btn:hover{
  background-color: #0fbd35;
}


/* PopMessage Section End  */






@media only screen and (max-width: 992px) {

  .form-btn {
    padding: 10px 0px 0px 0px !important;
    /* color: white; */
  }

  .btn-box,
  .pipe {
    color: blueviolet !important;
  }

  .btn-active {
    background-color: blueviolet;
    color: white !important;
  }
}
/* .px-5{
    padding-left:3rem !important;
    padding-right:3rem !important;
} */

</style>
